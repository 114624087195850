<template>
  <div class="">
    <h3 class="scroll-m-20 text-2xl font-semibold tracking-tight py-5">
      Sign in
    </h3>
    <AccountLoginForm/>
    <nuxt-link to="/register" class="flex justify-center items-center">
      <Button variant="ghost" class="whitespace-nowrap">
        Don't have an account?
        <Icon name="material-symbols:arrow-right-alt-rounded" size="20" class="mx-3" />

        <span class="underline">Register now</span>
      </Button>
    </nuxt-link>
    <GenericDialog width="100vw" :title="false" :footer="false">
      <template #trigger>
        <Button v-show="false" ref="dialogTrigger"></Button>
      </template>
      <template #body>
        <GetNotified :email="route.query?.email" />
      </template>

    </GenericDialog>
  </div>
</template>

<script setup lang="ts" >
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import * as z from 'zod'
import { Button } from '@/components/ui/button'
import { Loader2 } from 'lucide-vue-next'
import { FormField, FormItem, FormLabel, FormControl, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { onKeyStroke } from '@vueuse/core'  
import { useFocus } from '@vueuse/core'
const username = ref()
const { focused } = useFocus(username, { initialValue: true })

const uiStore = useUiStore()
const {toggleDialog} = uiStore

const client = useSupabaseClient()
const user = useSupabaseUser()
const loading = ref(false)
const authError = ref('')
const usingEmailPassword = ref(false)
const dialogTrigger = ref()
const showPassword = ref(false)

const formSchema = toTypedSchema(z.object({
    email: z.string().email().optional(),
    password: z.string().min(8).optional(),
}).transform((o) => ({ 
    email: o.email,
    password: o.password 
})))

const apiErrors = ref({
  wrongCredentials: false,
  Provider: false,
  accountNotVerified: false,
  accountSuspended: false
})

const apiErrorMessages = computed(() => {
  return {
    email: apiErrors.value.wrongCredentials ? 'Incorrect username or password' : apiErrors.value.accountSuspended ? 'Account suspended' : '',
    password: apiErrors.value.accountNotVerified ? 'Account not verified' : ''
    // Add more here as needed
  };
});

const { validate, errors, resetForm, handleSubmit, setErrors, meta: formMeta } = useForm({
  validationSchema: formSchema
})

const route = useRoute()

onMounted(() => {
  
  if (route.query.early_access) {  
    dialogTrigger.value.$el.click()
  }
})


watchEffect(async () => {
  if (user.value) {
    await navigateTo('/confirm')
  }
});

defineOgImageComponent('NuxtSeo', {
    title: 'SuperSeeded.ai',
    description: '',
    theme: '#c8c9d0',
    colorMode: 'light',
})


definePageMeta({
    layout: "auth",
    // no auth required here
    // middleware: "guest",
})




</script>
