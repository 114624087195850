<script lang="ts" setup>
import * as z from "zod";
import { useForm } from "vee-validate";
import { toTypedSchema } from "@vee-validate/zod";
import { Loader2 } from 'lucide-vue-next'
import { vAutoAnimate } from '@formkit/auto-animate'
const userStore = useUserStore()
const { setReceiveNewsletter } = userStore
const { receiveNewsletter } = storeToRefs(userStore)



const headers = useRequestHeaders(["cookie"]);
const loading = ref(false)
const invitesLoading = ref(false)
const showImage = ref(false);
const schema = z.object({
  invitedUsers: z.array(z.string().email().optional()).optional(),
});

const invitedUsers = ref([]);

const client = useSupabaseClient()


const {
  handleSubmit,
  meta,
  setErrors
} = useForm({
  validationSchema: toTypedSchema(schema),
});

const {email} = defineProps<{
  email: string
}>()


onMounted(() => {
  setReceiveNewsletter(true)
})



// const { subscribe } = useRealtimeChannel(userStore, 'users_waitlist', {
//   onUpdate: (record) => {
//     console.log(record)
//     if (receiveNewsletter.value !== record.value) {
//       setReceiveNewsletter(record.value)
//     }
//   },
//   onInsert: (record) => {
//     console.log(record)
//   }
// }, true);
let realtimeChannelNewsletterUpdate;

const getRealtimeChannel = (table: string, schema: string, callback: () => void) => {
  return client
    .channel(`${schema}:${table}`)
    .on(
      "postgres_changes",
      { event: "*", schema: schema, table: table },
      callback
    )
    .subscribe();
};

const onUpdate = (record: any) => {
  console.log(record)
  if (receiveNewsletter.value !== record.value) {
    setReceiveNewsletter(record.value)
  }
}

realtimeChannelNewsletterUpdate = getRealtimeChannel("users_waitlist", "public", onUpdate);


// await subscribe()



const {
  errorMessage,
  value,
  errors: invitedUsersError,
} = useField<string[]>("invitedUsers", {
  initialValue: [],
  label: "Invite users",
  validateOnMount: false,
  syncVModel: true,
});

const send = handleSubmit(async (values) => {
  invitesLoading.value = true
  const {data, error} = await useFetch('/api/users/invite', {
    method: 'POST',
    headers,
    body: {
      invitedUsers: values.invitedUsers
    }
  })
  if (error) {
    console.log('error', error)
    setErrors({
      invitedUsers: ['Error inviting users']
    })
  }
  invitesLoading.value = false
  push({
    title: 'Success',
    message: `Invites sent to ${invitedUsers.value.length} users`
  });
  
})

const toggleSubscription = async () => {
  loading.value = true
  // toggle receive_newsletter
  const receive_newsletter = !receiveNewsletter.value
  const {data, error} = await useFetch('/api/users/waitlist/toggle', {
    method: 'POST',
    headers,
    body: {
      receive_newsletter,
      email
    }
  })
  console.log(data.value.data[0].receive_newsletter)
  setReceiveNewsletter(data.value.data[0].receive_newsletter)
  loading.value = false
}

</script>

<template>
  <div
    class="flex flex-col md:flex-row items-start text-left min-h-[80vh]  md:min-h-[50vh] min-w-[80vw] md:min-w-[50vh] justify-start h-full">
    <div class="p-8 w-[100%]">
      <p class="pb-2 text-3xl font-semibold tracking-tight transition-colors first:mt-0 flex">
        <!-- <Icon v-if="!receiveNewsletter" name="ic:baseline-done" size="42" class="-mt-2 mr-2" /> -->
      <div class="">
        You're on the waitlist
        <Button @click="toggleSubscription()" variant="secondary" size="xs" class="flex p-1 mt-1 font-normal ">

          <Icon v-if="receiveNewsletter && !loading" name="material-symbols:mark-email-read-outline" size="16"
            class="mr-2 inline" />
          <Icon v-if="!receiveNewsletter && !loading" name="material-symbols:mail-off-outline-rounded" size="16"
            class="mr-2 inline" />
          <Loader2 v-if="loading" class="w-4 h-4 mr-2 animate-spin" />



          {{receiveNewsletter ? 'subscribed ' : 'unsubscribed'}}
          {{receiveNewsletter ? email : ''}}


        </Button>

      </div>
      </p>
      <p class="text-2xl font-semibold tracking-tight mt-10">Faster, more reliable planting design</p>


      <p>
      <ul class="my-6 ml-6 list-disc [&>li]:mt-1">
        <li>Trusted grow-based selection</li>
        <li>Stock availability and forecasting</li>
        <li>Industry feedback</li>
      </ul>
      </p>
      <FormField v-slot="{ componentField }" name="invitedUsers">
        <FormItem v-auto-animate>
          <FormLabel>Invite others</FormLabel>
          <FormControl>
            <TagsInput :delimiters="[32]" v-bind="componentField" :required="true" :addOnTab="true" :addOnPaste="true"
              v-model="invitedUsers">

              <TagsInputItem v-for="item in invitedUsers" :key="item" :value="item">
                <TagsInputItemText />
                <TagsInputItemDelete />
              </TagsInputItem>
              <TagsInputInput placeholder="Enter email address.." />


            </TagsInput>
            <span
              class="-top-10 relative inline-block float-right px-2 text-muted-foreground opacity-[0.5] whitespace-nowrap tracking-tight font-normal pointer-events-none text-sm">
              Enter for more
              <Icon name="ph:key-return-thin" class="size-6 text-muted-foreground" />
            </span>

          </FormControl>
          <FormMessage class="font-light" />
        </FormItem>
        <Button class="w-full mt-0" type="submit" :disabled="invitesLoading || invitedUsersError.length" @click="send">

          <Loader2 v-if="invitesLoading" class="w-4 h-4 mr-2 animate-spin" />Send
          invites
        </Button>
      </FormField>
    </div>
    <div
      class="flex relative flex-col -order-1 md:order-1 w-full md:flex-row md:space-x-4 lg:justify-between m-0 p-0 bg-center bg-cover bg-no-repeat bg-[url('/euc1.jpg')] h-full rounded-lg rounded-b-none md:rounded-l-none md:rounded-r-lg">
    </div>
  </div>
</template>
<style>
</style>